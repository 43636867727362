<template>
  <div class="card">
    <div class="card-header">
      <h4 class="text-secondary mb-0">Tipo de Exame</h4>
    </div>
    <div class="card-body pb-0">
      <LoadingData v-if="loadData" />
      <div v-else class="row">
        <div class="col-12">
          <div
            v-for="typeOfExam in typesOfExams"
            :key="typeOfExam.code"
            class="card card-body cursor-pointer"
            :class="
              selectedTypeOfExam.code == typeOfExam.code
                ? 'bg-success text-white'
                : ''
            "
            @click="setTypeOfExam(typeOfExam)"
          >
            <h5 class="mb-1">{{ typeOfExam.name }}</h5>
            <p class="mb-0">{{ typeOfExam.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h4 class="text-secondary mb-0">Lista de Exames</h4>
    </div>
    <div class="card-body">
      <div v-if="selectedTypeOfExam.code == null">
        <p class="mb-0">Por favor, selecione o tipo de exame primeiro.</p>
      </div>
      <LoadingData v-if="loadExams" />
      <ul v-if="exams.length > 0" class="mb-0">
        <li v-for="exam in exams" :key="exam.codigoExame">
          {{ exam.nomeExame }}
        </li>
      </ul>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h4 class="text-secondary mb-0">Observações</h4>
    </div>
    <div class="card-body">
      <textarea
        class="form-control"
        placeholder="Observações do agendamento"
        rows="3"
        v-model="observation"
      />
    </div>
    <div class="card-footer text-end">
      <router-link
        to="/managers/home"
        class="btn btn-link text-secondary"
        title="Cancelar Cadastro do Agendamento"
      >
        Cancelar
      </router-link>
      <button
        type="button"
        class="btn btn-flat-secondary ms-2"
        title="Voltar para Etapa Anterior"
        @click="$emit('backStep')"
      >
        Voltar Etapa
      </button>
      <button
        type="button"
        class="btn btn-secondary ms-2"
        title="Continuar"
        :disabled="!selectedTypeOfExam.code"
        @click="nextStep()"
      >
        Continuar
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/globals/LoadingData.vue'

export default {
  name: 'TypeOfExam',

  components: {
    LoadingData,
  },

  emits: ['backStep', 'setTypeOfExam'],

  data() {
    return {
      loadData: true,
      loadExams: false,
      typesOfExams: [],
      exams: [],
      selectedTypeOfExam: {
        code: null,
      },
      observation: '',
    }
  },

  computed: {
    ...mapGetters(['scheduling', 'companyCode']),
  },

  methods: {
    async getTypesOfExam() {
      try {
        this.typesOfExams = await this.$store.dispatch('getTypesOfExam')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    setTypeOfExam(typeOfExam) {
      this.selectedTypeOfExam = typeOfExam
      this.getExamsByCollaborator()
    },

    async getExamsByCollaborator() {
      this.loadExams = true
      try {
        this.exams = []

        this.exams = await this.$store.dispatch('getExamsByCollaborator', {
          companyCode: this.companyCode,
          collaboratorCode: this.scheduling.collaborator.CODIGO,
          typeOfExam: this.selectedTypeOfExam.type,
        })

        this.selectedTypeOfExam.exams = this.exams

        this.loadExams = false
      } catch (error) {
        this.loadExams = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    nextStep() {
      this.$emit('setTypeOfExam', {
        typeOfExam: this.selectedTypeOfExam,
        observation: this.observation,
      })
    },
  },

  mounted() {
    this.getTypesOfExam()
  },
}
</script>
