<template>
  <div class="card">
    <div class="card-header">
      <h4 class="text-secondary mb-0">Horário</h4>
    </div>
    <div class="card-body pb-0">
      <div class="row">
        <div class="col-12">
          <h6 class="text-center">Selecione uma Unidade</h6>
        </div>
        <div
          v-for="unity in scheduling.city.unities"
          :key="unity.code"
          class="d-flex col-lg-4"
          @click="setUnity(unity)"
        >
          <div
            class="card card-body cursor-pointer"
            :class="
              selectedUnity.code == unity.code ? 'bg-success text-white' : ''
            "
          >
            <p class="mb-0">{{ unity.name }}</p>
            <p class="mb-0">
              <small>{{ unity.address }}</small>
            </p>
          </div>
        </div>
      </div>
      <hr class="mt-2" />
      <div v-if="isSelectedUnity" class="row mb-4">
        <div class="col-12">
          <h6 class="text-center">Selecione uma Data e Hora</h6>
          <div v-if="loadData">
            <LoadingData />
          </div>
          <div v-else>
            <div class="row" v-if="dateTimeAvaliable.length === 0">
              <div class="col-12">
                <p>Nenhum horário disponível, selecione outra agenda.</p>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-3">
                <div
                  v-for="(day, index) in avaliableDates"
                  :key="index"
                  class="w-100"
                >
                  <input
                    type="radio"
                    class="btn-check"
                    :id="`option_date_${index}`"
                    name="selectedDate"
                    :value="day"
                    v-model="selectedDate"
                  />
                  <label
                    class="btn my-1 w-100"
                    :class="selectedDate == day ? 'btn-success' : 'btn-light'"
                    :for="`option_date_${index}`"
                  >
                    {{ day }}
                  </label>
                </div>
              </div>
              <div class="col-9">
                <div class="row">
                  <div
                    v-for="(time, index) in avaliableTimes"
                    :key="index"
                    class="col-2"
                  >
                    <input
                      type="radio"
                      class="btn-check"
                      :id="`option_time_${index}`"
                      name="selectedTime"
                      :value="time.horario"
                      v-model="selectedTime"
                    />
                    <label
                      class="btn my-1 w-100"
                      :class="
                        selectedTime == time.horario
                          ? 'btn-success'
                          : 'btn-light'
                      "
                      :for="`option_time_${index}`"
                    >
                      {{ time.horario }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-end">
      <router-link
        to="/managers/home"
        class="btn btn-link text-secondary"
        title="Cancelar Cadastro do Agendamento"
      >
        Cancelar
      </router-link>
      <button
        type="button"
        class="btn btn-flat-secondary ms-2"
        title="Voltar para Etapa Anterior"
        @click="$emit('backStep')"
      >
        Voltar Etapa
      </button>
      <button
        type="button"
        class="btn btn-secondary ms-2"
        title="Continuar"
        :disabled="disabledButton"
        @click="nextStep()"
      >
        Continuar
      </button>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import { mapGetters } from 'vuex'
import { dateIncreaseDay } from '@/utils/date'

export default {
  name: 'DirectSchedule',

  components: {
    LoadingData,
  },

  data() {
    return {
      loadData: true,
      disabledButton: true,
      isSelectedUnity: false,
      selectedUnity: {
        code: null,
      },
      dateTimeAvaliable: [],
      avaliableDates: [],
      avaliableTimes: [],
      selectedDate: null,
      selectedTime: null,
      selectedDateTime: null,
    }
  },

  computed: {
    ...mapGetters(['scheduling', 'companyCode']),
  },

  watch: {
    selectedUnity: {
      handler(newVal, oldVal) {
        if (newVal.code !== null) {
          this.isSelectedUnity = true
          this.getAvaliableTimes()
        } else {
          this.isSelectedUnity = false
        }
      },
      immediate: true,
    },
    selectedDate: {
      handler(newVal, oldVal) {
        if (newVal !== null) {
          this.selectedTime = null
          this.showTimeList()
        }
      },
      immediate: true,
    },
    selectedTime: {
      handler(newVal, oldVal) {
        if (newVal !== null) {
          this.selectedDateTime = `${this.selectedDate} ${this.selectedTime}`
          this.disabledButton = false
        }
      },
      immediate: true,
    },
  },

  methods: {
    setUnity(unity) {
      this.selectedUnity = unity
    },

    async getAvaliableTimes() {
      try {
        this.loadData = true
        this.avaliableDates = []
        this.avaliableTimes = []

        this.dateTimeAvaliable = await this.$store.dispatch(
          'getAvaliableTimes',
          {
            companyCode: this.companyCode,
            unityCode: this.selectedUnity.code,
            startDate: dateIncreaseDay(1),
            endDate: dateIncreaseDay(7),
          },
        )

        this.avaliableDates = this.dateTimeAvaliable.map(function (date) {
          return date.data
        })

        if (this.dateTimeAvaliable.length > 0) {
          const [firstDate] = this.avaliableDates
          this.selectedDate = firstDate
        } else {
          this.selectedDate = null
          this.selectedTime = null
        }

        this.avaliableDates = [...new Set(this.avaliableDates)]

        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    showTimeList() {
      this.avaliableTimes = this.dateTimeAvaliable.filter(
        (date) => date.data === this.selectedDate,
      )

      const [firstTime] = this.avaliableTimes
      if (firstTime) {
        this.selectedTime = firstTime.horario
      }

      this.avaliableTimes = [...new Set(this.avaliableTimes)]
    },

    nextStep() {
      this.$emit('setDateTime', {
        unity: this.selectedUnity,
        dateTime: this.selectedDateTime,
      })
    },
  },
}
</script>
