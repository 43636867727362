<template>
  <div class="card">
    <div class="card-header">
      <h4 class="text-secondary mb-0">Tipo de Agendamento</h4>
    </div>
    <div class="card-body">
      <LoadingData v-if="loadData" />
      <div v-else>
        <div class="row">
          <div class="col-12">
            <h6 class="text-center">Selecione o tipo de agendamento</h6>
          </div>
          <div
            v-for="typeOfSchedule in typesOfSchedules"
            :key="typeOfSchedule.code"
            class="d-flex col-sm-6"
            @click="setTypeOfSchedule(typeOfSchedule)"
          >
            <div
              class="card card-body cursor-pointer"
              :class="
                selectedTypeOfSchedule.code == typeOfSchedule.code
                  ? 'bg-success text-white'
                  : ''
              "
            >
              <div class="text-center">
                <i class="ph-3x" :class="typeOfSchedule.webIcon"></i>
                <h5>{{ typeOfSchedule.description }}</h5>
                <p class="mb-0">{{ typeOfSchedule.details }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedTypeOfSchedule.code" class="row">
          <div class="col-12">
            <h6 class="text-center">Selecione uma cidade</h6>
          </div>
          <div
            class="d-flex col"
            v-for="city in cities"
            :key="city.id"
            @click="setCity(city)"
          >
            <div
              class="card card-body cursor-pointer"
              :class="selectedCity.id == city.id ? 'bg-success text-white' : ''"
            >
              <div class="text-center">
                <h5 class="mb-0">{{ city.name }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showPreferredRegion">
        <hr />
        <h6 class="text-center">Região de preferência</h6>
        <textarea
          class="form-control"
          placeholder="Região de preferência"
          rows="2"
          v-model="preferredRegion"
        />
      </div>
    </div>
    <div class="card-footer text-end">
      <router-link
        to="/managers/home"
        class="btn btn-link text-secondary"
        title="Cancelar Cadastro do Agendamento"
      >
        Cancelar
      </router-link>
      <button
        type="button"
        class="btn btn-flat-secondary ms-2"
        title="Voltar para Etapa Anterior"
        @click="$emit('backStep')"
      >
        Voltar Etapa
      </button>
      <button
        type="button"
        class="btn btn-secondary ms-2"
        title="Continuar"
        :disabled="
          !selectedCity.id ||
          (selectedCity.id === cityEnum.OUTRAS_LOCALIDADES && !preferredRegion)
        "
        @click="nextStep()"
      >
        Continuar
      </button>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import cityEnum from '@/enum/cityEnum'

export default {
  name: 'TypeOfSchedule',

  components: {
    LoadingData,
  },

  data() {
    return {
      loadData: true,
      showPreferredRegion: false,
      typesOfSchedules: [],
      cities: [],
      cityEnum,
      selectedTypeOfSchedule: {
        code: null,
      },
      selectedCity: {
        id: null,
      },
      preferredRegion: null,
    }
  },

  watch: {
    selectedCity: {
      handler(newVal, oldVal) {
        if (newVal.id && newVal.id === cityEnum.OUTRAS_LOCALIDADES) {
          this.showPreferredRegion = true
        } else {
          this.showPreferredRegion = false
          this.preferredRegion = null
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getTypesOfSchedules() {
      try {
        this.typesOfSchedules = await this.$store.dispatch(
          'getTypesOfSchedules',
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    setTypeOfSchedule(typeOfSchedule) {
      this.selectedTypeOfSchedule = {
        code: typeOfSchedule.code,
        description: typeOfSchedule.description,
        webIcon: typeOfSchedule.webIcon,
        details: typeOfSchedule.details,
      }
      this.cities = typeOfSchedule.cities
      this.selectedCity = { id: null }
    },

    setCity(city) {
      this.selectedCity = city
    },

    nextStep() {
      this.$emit('setTypeOfSchedule', {
        typeOfSchedule: this.selectedTypeOfSchedule,
        city: this.selectedCity,
        preferredRegion: this.preferredRegion,
      })
    },
  },

  mounted() {
    this.getTypesOfSchedules()
  },
}
</script>
