<template>
  <div class="card">
    <div class="card-header">
      <h4 class="text-secondary mb-0">Confirmação</h4>
    </div>
    <div class="card-body pb-1">
      <div class="row">
        <div class="col-12">
          <h6>Dados do Funcionário:</h6>
        </div>
        <div class="col-12">
          <p><strong>Nome:</strong> {{ scheduling.collaborator.NOME }}</p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>Unidade:</strong> {{ scheduling.collaborator.NOMEUNIDADE }}
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p><strong>Setor:</strong> {{ scheduling.collaborator.NOMESETOR }}</p>
        </div>
        <div class="col-12 col-md-6">
          <p><strong>Cargo:</strong> {{ scheduling.collaborator.NOMECARGO }}</p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>Matrícula:</strong>
            {{ scheduling.collaborator.MATRICULAFUNCIONARIO }}
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>CPF:</strong> {{ scheduling.collaborator.CPFFUNCIONARIO }}
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>Data de Nascimento:</strong>
            {{ scheduling.collaborator.DATA_NASCIMENTO }}
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>Data de Admissão:</strong>
            {{ scheduling.collaborator.DATA_ADMISSAO }}
          </p>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <h6>Formas de Contato:</h6>
        </div>
        <div
          v-for="(contact, index) in scheduling.contactMethod"
          :key="index"
          class="col-12 col-md-6"
        >
          <p>
            <strong>{{ contact.contactType }}:</strong> {{ contact.value }}
          </p>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <h6>Dados do Agendamento:</h6>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>Tipo de Exame:</strong> {{ scheduling.typeOfExam.name }}
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>Tipo de Agendamento:</strong>
            {{ scheduling.typeOfSchedule.description }}
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p><strong>Cidade:</strong> {{ scheduling.city.name }}</p>
        </div>
        <div
          v-if="scheduling.city.id !== cityEnum.OUTRAS_LOCALIDADES"
          class="col-12 col-md-6"
        >
          <p>
            <strong>Unidade: </strong>
            <span
              v-if="scheduling.typeOfSchedule.code === typeScheduleEnum.DIRETO"
            >
              {{ scheduling.unity.name }} ({{ scheduling.unity.address }})
            </span>
            <span
              v-if="
                scheduling.typeOfSchedule.code === typeScheduleEnum.COLABORADOR
              "
            >
              À definir pelo funcionário
            </span>
          </p>
        </div>
        <div
          v-if="scheduling.city.id !== cityEnum.OUTRAS_LOCALIDADES"
          class="col-12 col-md-6"
        >
          <p>
            <strong>Data e Hora: </strong>
            <span
              v-if="scheduling.typeOfSchedule.code === typeScheduleEnum.DIRETO"
            >
              {{ scheduling.dateTime }}
            </span>
            <span
              v-if="
                scheduling.typeOfSchedule.code === typeScheduleEnum.COLABORADOR
              "
            >
              À definir pelo funcionário
            </span>
          </p>
        </div>
        <div v-if="scheduling.preferredRegion" class="col-6">
          <p>
            <strong>Região de Preferência:</strong>
            {{ scheduling.preferredRegion }}
          </p>
        </div>
        <div
          v-if="
            scheduling.typeOfSchedule.code === typeScheduleEnum.DIRETO &&
            scheduling.city.id === cityEnum.OUTRAS_LOCALIDADES
          "
          class="col-12"
        >
          <strong>Sugestões de Data:</strong>
          <ul class="mt-1">
            <li v-for="(i, key) in scheduling.dateTime" :key="key">
              {{ dateTimeSqlToDateTimeBR(`${i.date} ${i.time}`) }}
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <h6>Lista de Exames:</h6>
        </div>
        <div class="col-12">
          <ul v-if="scheduling.typeOfExam.exams.length > 0">
            <li
              v-for="exam in scheduling.typeOfExam.exams"
              :key="exam.codigoExame"
            >
              {{ exam.nomeExame }}
            </li>
          </ul>
        </div>
        <div v-if="scheduling.observation" class="col-12">
          <p><strong>Observações:</strong> {{ scheduling.observation }}</p>
        </div>
      </div>
    </div>
    <div class="card-footer text-end">
      <router-link
        to="/managers/home"
        class="btn btn-link text-secondary"
        title="Cancelar Cadastro do Agendamento"
      >
        Cancelar
      </router-link>
      <button
        type="button"
        class="btn btn-flat-secondary ms-2"
        title="Voltar para Etapa Anterior"
        @click="$emit('backStep')"
      >
        Voltar Etapa
      </button>
      <button
        type="button"
        class="btn btn-secondary ms-2"
        :title="labelButton"
        :disabled="disableButton"
        @click="save()"
      >
        <span v-if="disableButton">
          <i class="ph-spinner spinner" />
        </span>
        <span v-else>{{ labelButton }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'
import { mapGetters } from 'vuex'
import { dateTimeSqlToDateTimeBR } from '@/utils/date'
import typeScheduleEnum from '@/enum/typeScheduleEnum'
import cityEnum from '@/enum/cityEnum'

export default {
  name: 'ValidationData',

  data() {
    return {
      disableButton: false,
      dateTimeSqlToDateTimeBR,
      typeScheduleEnum,
      cityEnum,
    }
  },

  computed: {
    ...mapGetters(['scheduling']),

    labelButton() {
      return this.scheduling.typeOfSchedule.code === typeScheduleEnum.DIRETO
        ? 'Agendar Exame'
        : 'Solicitar Agendamento de Exame'
    },
  },

  methods: {
    async save() {
      try {
        this.disableButton = true
        const response = await this.$store.dispatch(
          'saveSchedule',
          this.scheduling,
        )
        this.$root.$refs.notification.success(response.message)
        this.disableButton = false
        this.$router.push(PATHS.MANAGER_HOME)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
}
</script>
