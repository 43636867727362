<template>
  <div class="content">
    <Steps :currentStep="currentStep" />
    <Collaborator v-if="currentStep === 1" @setCollaborator="setCollaborator" />
    <Contact v-if="currentStep === 2" @setContact="setContact" />
    <TypeOfExam
      v-if="currentStep === 3"
      @backStep="currentStep = 2"
      @setTypeOfExam="setTypeOfExam"
    />
    <TypeOfSchedule
      v-if="currentStep === 4"
      @backStep="currentStep = 3"
      @setTypeOfSchedule="setTypeOfSchedule"
    />
    <DirectSchedule
      v-if="
        currentStep === 5 &&
        scheduling.typeOfSchedule.code === typeScheduleEnum.DIRETO &&
        this.scheduling.city.id !== cityEnum.OUTRAS_LOCALIDADES
      "
      @backStep="currentStep = 4"
      @setDateTime="setDateTime"
    />
    <SuggestSchedule
      v-if="
        currentStep === 5 &&
        scheduling.typeOfSchedule.code === typeScheduleEnum.DIRETO &&
        this.scheduling.city.id === cityEnum.OUTRAS_LOCALIDADES
      "
      @backStep="currentStep = 4"
      @setDateTimeSugest="setDateTimeSugest"
    />
    <SelectScheduleNotNecessary
      v-if="
        currentStep === 5 &&
        scheduling.typeOfSchedule.code === typeScheduleEnum.COLABORADOR
      "
      @backStep="currentStep = 4"
      @setDateTimeSugest="setDateTimeSugest"
    />
    <ValidationData v-if="currentStep === 6" @backStep="currentStep = 5" />
  </div>
</template>

<script>
import Steps from '@/components/managers/schedules/new/Steps.vue'
import Collaborator from '@/components/managers/schedules/new/Collaborator.vue'
import Contact from '@/components/managers/schedules/new/Contact.vue'
import TypeOfExam from '@/components/managers/schedules/new/TypeOfExam.vue'
import TypeOfSchedule from '@/components/managers/schedules/new/TypeOfSchedule.vue'
import DirectSchedule from '@/components/managers/schedules/new/DirectSchedule.vue'
import SuggestSchedule from '@/components/managers/schedules/new/SuggestSchedule.vue'
import SelectScheduleNotNecessary from '@/components/managers/schedules/new/SelectScheduleNotNecessary.vue'
import ValidationData from '@/components/managers/schedules/new/ValidationData.vue'
import { mapGetters } from 'vuex'
import typeScheduleEnum from '@/enum/typeScheduleEnum'
import cityEnum from '@/enum/cityEnum'

export default {
  name: 'NewSchedule',

  components: {
    Steps,
    Collaborator,
    Contact,
    TypeOfExam,
    TypeOfSchedule,
    DirectSchedule,
    SuggestSchedule,
    SelectScheduleNotNecessary,
    ValidationData,
  },

  data() {
    return {
      currentStep: 1,
      typeScheduleEnum,
      cityEnum,
    }
  },

  computed: {
    ...mapGetters(['scheduling']),
  },

  methods: {
    setCollaborator(collaborator) {
      this.$store.dispatch('setCollaborator', collaborator)
      this.currentStep = 2
    },

    setContact(contactMethod) {
      this.$store.dispatch('setContact', contactMethod)
      this.currentStep = 3
    },

    setTypeOfExam(data) {
      this.$store.dispatch('setTypeOfExam', data)
      this.currentStep = 4
    },

    setTypeOfSchedule(data) {
      this.$store.dispatch('setTypeOfSchedule', data)
      this.currentStep = 5
    },

    setDateTime(data) {
      this.$store.dispatch('setDateTime', data)
      this.currentStep = 6
    },

    setDateTimeSugest(data) {
      this.$store.dispatch('setDateTimeSugest', data)
      this.currentStep = 6
    },
  },

  mounted() {
    this.$store.dispatch('resetScheduling')
  },
}
</script>
