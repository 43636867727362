<template>
  <UpdateDateScheduleModal
    v-if="showUpdateDateScheduleModal"
    :uuid="schedule.schedule.uuid"
    :user="user.name"
    @closeModal="closeUpdateDateScheduleModal"
  />
  <CancelScheduleModal
    v-if="showCancelScheduleModal"
    :uuid="schedule.schedule.uuid"
    :user="user.name"
    @closeModal="closeCancelScheduleModal"
  />
  <div class="content">
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div class="card-header d-flex align-items-center py-0">
        <h4 class="text-secondary py-3 mb-0">
          Detalhes do Agendamento
          <span class="fs-base">(Empresa: {{ companyCode }})</span>
        </h4>
        <div class="ms-auto">
          <router-link
            to="/managers/home"
            class="btn btn-outline-dark"
            title="Voltar Para à Tela Inicial"
          >
            Voltar Para à Tela Inicial
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h6>Dados do Funcionário:</h6>
          </div>
          <div class="col-12">
            <p>
              <strong>Nome:</strong> {{ schedule.schedule.collaboratorName }}
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p><strong>CPF:</strong> {{ schedule.schedule.collaboratorCpf }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              <strong>Código:</strong> {{ schedule.schedule.collaboratorCode }}
            </p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <h6>Formas de Contato:</h6>
          </div>
          <div
            v-for="contact in schedule.contactMethod"
            :key="contact.id"
            class="col-12 col-md-6"
          >
            <p>
              <strong>{{ contact.name }}:</strong> {{ contact.value }}
            </p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <h6>Dados do Agendamento:</h6>
          </div>
          <div
            class="col-12 col-md-6"
            :class="{ 'col-md-12': !schedule.schedule.socId }"
          >
            <p>
              <strong>Tipo de Agendamento: </strong>
              <span
                v-html="
                  typeLabel(
                    schedule.schedule.calendarType,
                    schedule.schedule.type,
                  )
                "
              />
            </p>
          </div>
          <div v-if="schedule.schedule.socId" class="col-12 col-md-6">
            <p><strong>Código SOC:</strong> {{ schedule.schedule.socId }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              <strong>Tipo de Exame:</strong>
              {{ schedule.schedule.typeOfExamName }}
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              <strong>Status: </strong>
              <span
                v-html="
                  statusLabel(
                    schedule.schedule.calendarStatus,
                    schedule.schedule.status,
                  )
                "
              />
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              <strong>Cidade:</strong>
              {{ schedule.schedule.cityName }}
            </p>
          </div>
          <div v-if="schedule.schedule.preferredRegion" class="col-12 col-md-6">
            <p>
              <strong>Região de Preferência:</strong>
              {{ schedule.schedule.preferredRegion }}
            </p>
          </div>
          <div v-if="schedule.schedule.unityName" class="col-12 col-md-6">
            <p>
              <strong>Unidade:</strong> {{ schedule.schedule.unityName }} ({{
                schedule.schedule.unityAddress
              }})
            </p>
          </div>
          <div v-if="schedule.schedule.providerName" class="col-12 col-md-12">
            <p>
              <strong>Clínica Parceira:</strong>
              {{ schedule.schedule.providerName }} ({{
                schedule.schedule.providerAddress
              }})
            </p>
          </div>
          <div v-if="schedule.schedule.date" class="col-12 col-md-6">
            <p>
              <strong>Data:</strong>
              {{
                schedule.schedule.date
                  ? dateTimeSqlToDateBR(schedule.schedule.date) +
                    ' ' +
                    schedule.schedule.time
                  : 'Não Selecionada'
              }}
            </p>
          </div>
          <div
            v-if="
              schedule.schedule.cityId == cityEnum.OUTRAS_LOCALIDADES &&
              schedule.dataSuggestion.length > 0
            "
            class="col-12"
          >
            <strong>Sugestões de Data:</strong>
            <ul class="mt-1">
              <li
                v-for="dataSuggestion in schedule.dataSuggestion"
                :key="dataSuggestion.id"
              >
                {{
                  dateTimeSqlToDateTimeBR(
                    `${dataSuggestion.date} ${dataSuggestion.time}`,
                  )
                }}
              </li>
            </ul>
          </div>
          <div v-if="schedule.schedule.canceledReason" class="col-12">
            <p>
              <strong>Motivo do Cancelamento:</strong>
              {{ schedule.schedule.canceledReason }}
            </p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <h6>Lista de Exames:</h6>
          </div>
          <div v-if="schedule.exams" class="col-12">
            <ul v-if="schedule.exams.length > 0">
              <li v-for="exam in schedule.exams" :key="exam.codigoExame">
                {{ exam.nomeExame }}
              </li>
            </ul>
            <ul v-else>
              <li>Nenhum exame encontrado</li>
            </ul>
          </div>
          <div v-if="schedule.schedule.observation" class="col-12">
            <p>
              <strong>Observações:</strong> {{ schedule.schedule.observation }}
            </p>
          </div>
        </div>
      </div>
      <div class="card-footer text-end">
        <router-link
          to="/managers/schedules/list"
          class="btn btn-flat-secondary"
          title="Voltar"
        >
          Voltar
        </router-link>
        <button
          v-if="canCancelSchedule"
          type="button"
          class="btn btn-danger ms-2"
          title="Cancelar Agendamento"
          @click="showCancelScheduleModal = true"
        >
          Cancelar
        </button>
        <button
          v-if="canEditSchedule"
          type="button"
          class="btn btn-warning ms-2"
          title="Alterar Data e Hora do Agendamento"
          @click="showUpdateDateScheduleModal = true"
        >
          Alterar Data/Hora
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import UpdateDateScheduleModal from '@/components/commons/schedules/UpdateDateScheduleModal.vue'
import CancelScheduleModal from '@/components/commons/schedules/CancelScheduleModal.vue'
import { mapGetters } from 'vuex'
import { statusLabel, typeLabel } from '@/utils/strings'
import { dateTimeSqlToDateTimeBR, dateTimeSqlToDateBR } from '@/utils/date'
import cityEnum from '@/enum/cityEnum'
import calendarStatusEnum from '@/enum/calendarStatusEnum'

export default {
  name: 'DetailsSchedule',

  components: {
    LoadingData,
    UpdateDateScheduleModal,
    CancelScheduleModal,
  },

  data() {
    return {
      uuid: this.$route.params.uuid,
      loadData: true,
      showUpdateDateScheduleModal: false,
      showCancelScheduleModal: false,
      schedule: {},
      cityEnum,
      statusLabel,
      typeLabel,
      dateTimeSqlToDateTimeBR,
      dateTimeSqlToDateBR,
    }
  },

  computed: {
    ...mapGetters(['user', 'companyCode']),

    canCancelSchedule() {
      if (
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.CANCELADO ||
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.COMPARECEU ||
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.NAO_COMPARECEU
      ) {
        return false
      }

      if (this.schedule.schedule.date) {
        const dateTimeNow = new Date()
        const dateTimeSchedule = new Date(
          `${this.schedule.schedule.date} ${this.schedule.schedule.time}`,
        )
        const dateTimeLimite = new Date(
          dateTimeSchedule.getTime() - 1000 * 60 * 60 * 24,
        )

        if (dateTimeLimite < dateTimeNow) {
          return false
        }
      }
      return true
    },

    canEditSchedule() {
      if (
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.CANCELADO ||
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.COMPARECEU ||
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.NAO_COMPARECEU ||
        this.schedule.schedule.cityId === cityEnum.OUTRAS_LOCALIDADES
      ) {
        return false
      }

      if (this.schedule.schedule.date) {
        const dateTimeNow = new Date()
        const dateTimeSchedule = new Date(
          `${this.schedule.schedule.date} ${this.schedule.schedule.time}`,
        )
        const dateTimeLimite = new Date(
          dateTimeSchedule.getTime() - 1000 * 60 * 60 * 24,
        )

        if (dateTimeLimite < dateTimeNow) {
          return false
        }
      }
      return true
    },
  },

  methods: {
    async getSchedule() {
      try {
        this.schedule = await this.$store.dispatch('getSchedule', {
          uuid: this.uuid,
        })

        this.schedule.exams = await this.$store.dispatch(
          'getExamsByCollaborator',
          {
            companyCode: this.schedule.schedule.companyCode,
            collaboratorCode: this.schedule.schedule.collaboratorCode,
            typeOfExam: this.schedule.schedule.typeOfExamType,
          },
        )

        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$router.push({
          name: 'ManagerSchedulesList',
        })
      }
    },

    closeUpdateDateScheduleModal(reloadData) {
      if (reloadData) {
        this.getSchedule()
      }
      this.showUpdateDateScheduleModal = false
    },

    closeCancelScheduleModal(redirect) {
      this.showCancelScheduleModal = false
      if (redirect) {
        this.$router.push({
          name: 'ManagerSchedulesList',
        })
      }
    },
  },

  mounted() {
    this.getSchedule()
  },
}
</script>
