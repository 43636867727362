<template>
  <div class="card">
    <div class="table-responsive">
      <table
        class="table table-sm table-bordered table-striped"
        :id="dataTable.id"
      >
        <thead>
          <tr>
            <th>Funcionário</th>
            <th class="text-center">Tipo de Agendamento</th>
            <th class="text-center">Tipo de Exame</th>
            <th class="text-center">Cidade</th>
            <th class="text-center">Data</th>
            <th class="text-center">Hora</th>
            <th class="text-center">Status</th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="schedule in schedules" :key="schedule.uuid">
            <td>{{ schedule.collaboratorName }}</td>
            <td
              v-html="typeLabel(schedule.calendarType, schedule.type)"
              class="text-center"
            />
            <td class="text-center">{{ schedule.typeOfExam }}</td>
            <td class="text-center">{{ schedule.cityName }}</td>
            <td class="text-center">
              {{
                schedule.date
                  ? dateTimeSqlToDateBR(schedule.date)
                  : 'Não Selecionada'
              }}
            </td>
            <td class="text-center">
              {{ schedule.time ? schedule.time : 'Não Selecionada' }}
            </td>
            <td
              v-html="statusLabel(schedule.calendarStatus, schedule.status)"
              class="text-center"
            />
            <td class="text-center">
              <router-link
                :to="`details/${schedule.uuid}`"
                class="btn btn-success btn-icon btn-sm"
                title="Visualizar Detalhes"
              >
                <i class="ph-info ph-sm"></i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import '@/utils/basicDataTableExtend'
import { statusLabel, typeLabel } from '@/utils/strings'
import { dateTimeSqlToDateBR } from '@/utils/date'

export default {
  name: 'SchedulesTable',

  props: {
    schedules: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListSchedules',
        columnDefs: [
          {
            targets: [7],
            orderable: false,
            width: 100,
          },
        ],
      },
      statusLabel,
      typeLabel,
      dateTimeSqlToDateBR,
    }
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>

<style scoped>
td {
  padding: 0.5rem !important;
}
</style>
