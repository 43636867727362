<template>
  <div class="card">
    <div class="card-header d-flex align-items-center py-0">
      <h4 class="text-secondary py-3 mb-0">Funcionário</h4>
      <div class="ms-auto">
        <router-link
          to="/managers/collaborators/new"
          class="btn btn-secondary"
          title="Novo Funcionário"
        >
          <i class="ph ph-user-plus me-2"></i>
          Novo Funcionário
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="col-12 col-lg-8 offset-lg-2">
        <h6 class="text-center">BUSCAR FUNCIONÁRIO</h6>
        <div class="position-relative">
          <input
            type="search"
            class="form-control text-center"
            placeholder="Digite pelo menos 3 dígitos do CPF do Funcionário"
            v-model="termSearchCollaborator"
            v-maska="'###########'"
          />
          <div class="position-absolute end-0 top-50 translate-middle-y me-1">
            <button
              type="button"
              class="btn btn-success btn-icon"
              @click="getCollaborators()"
            >
              <i class="ph-magnifying-glass ph-sm"></i>
            </button>
          </div>
        </div>
        <p class="text-center mt-2 fs-sm">
          <em>
            Digite pelo menos 3 dígitos do CPF do Funcionário e clique em
            pesquisar...
          </em>
        </p>
        <h6 v-if="isSearching" class="text-center">Buscando...</h6>
        <h6
          v-if="
            collaborators.length === 0 &&
            termSearchCollaborator.length >= 3 &&
            !isSearching
          "
          class="text-center"
        >
          Nenhum Funcionário encontrado
        </h6>
      </div>
      <div
        v-if="collaborators.length > 0"
        class="list-collaborator col-12 col-lg-8 offset-lg-2"
      >
        <div
          class="card bg-secondary bg-opacity-10"
          v-for="(collaborator, index) in collaborators"
          :key="index"
        >
          <div
            class="d-flex align-items-center p-1"
            :class="{ 'cursor-pointer': collaborator.SITUACAO === 'Ativo' }"
            :title="
              collaborator.SITUACAO === 'Ativo' ? 'Selecionar Funcionário' : ''
            "
            @click="setCollaborator(collaborator)"
          >
            <div class="ps-2 me-3">
              <button
                v-if="collaborator.SITUACAO === 'Ativo'"
                class="btn btn-icon btn-success"
                title="Status Ativo"
              >
                <i class="ph ph-check"></i>
              </button>
              <button
                v-else
                class="btn btn-icon btn-secondary"
                title="Status Inativo"
                disabled
              >
                <i class="ph ph-x"></i>
              </button>
            </div>
            <div>
              <p class="text-primary fw-semibold fs-lg mb-0">
                {{ collaborator.NOME }}
              </p>
              <p class="mb-0">Unidade: {{ collaborator.NOMEUNIDADE }}</p>
              <p class="mb-0">Setor: {{ collaborator.NOMESETOR }}</p>
              <p class="mb-0">Cargo: {{ collaborator.NOMECARGO }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-end">
      <router-link
        to="/managers/home"
        class="btn btn-link text-secondary"
        title="Cancelar Cadastro do Agendamento"
      >
        Cancelar
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { maska } from 'maska'

export default {
  name: 'Collaborator',

  directives: { maska },

  data() {
    return {
      termSearchCollaborator: '',
      isSearching: false,
      collaborators: [],
    }
  },

  computed: {
    ...mapGetters(['companyCode']),
  },

  watch: {
    termSearchCollaborator: {
      handler(newVal, oldVal) {
        if (newVal.length >= 3) {
          this.getCollaborators()
        } else {
          this.collaborators = []
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getCollaborators() {
      this.isSearching = true
      try {
        this.collaborators = []

        this.collaborators = await this.$store.dispatch('getCollaborators', {
          companyCode: this.companyCode,
          search: this.termSearchCollaborator,
        })

        this.isSearching = false
      } catch (error) {
        this.isSearching = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    setCollaborator(selectedCollaborator) {
      if (selectedCollaborator.SITUACAO === 'Ativo') {
        this.$emit('setCollaborator', selectedCollaborator)
      }
    },
  },
}
</script>

<style scoped>
.list-collaborator {
  max-height: 500px;
  margin-top: 20px;
  overflow-y: scroll;
}
</style>
