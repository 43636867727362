<template>
  <div class="card">
    <div class="card-header">
      <h4 class="text-secondary mb-0">Horário</h4>
    </div>
    <div class="card-body">
      <h6 class="text-center">Atenção</h6>
      <p class="text-center mb-0">
        Agendamento será feito pelo funcionário, clique em continuar para
        prosseguirmos com o registro
      </p>
    </div>
    <div class="card-footer text-end">
      <router-link
        to="/managers/home"
        class="btn btn-link text-secondary"
        title="Cancelar Cadastro do Agendamento"
      >
        Cancelar
      </router-link>
      <button
        type="button"
        class="btn btn-flat-secondary ms-2"
        title="Voltar para Etapa Anterior"
        @click="$emit('backStep')"
      >
        Voltar Etapa
      </button>
      <button
        type="button"
        class="btn btn-secondary ms-2"
        title="Continuar"
        @click="nextStep()"
      >
        Continuar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectScheduleNotNecessary',

  methods: {
    nextStep() {
      this.$emit('setDateTimeSugest', null)
    },
  },
}
</script>
