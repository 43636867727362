<template>
  <div class="card">
    <div class="card-header d-flex align-items-center py-0">
      <h4 class="text-secondary py-3 mb-0">Horário</h4>
      <div class="ms-auto">
        <button
          type="button"
          class="btn btn-sm btn-outline-dark"
          title="Adicionar mais uma opção de sugestão"
          @click="addOption()"
        >
          Adicionar Sugestão
        </button>
      </div>
    </div>
    <div class="card-body pb-0">
      <div class="row">
        <div class="col-12">
          <h6 class="text-center">
            Indique as datas e horários desejados para a escolha do funcionário
          </h6>
        </div>
        <div v-for="(calendar, index) in calendars" :key="index" class="col">
          <div class="card card-body">
            <fieldset>
              <legend class="fs-base fw-bold border-bottom pb-2 mb-3">
                <div class="d-flex justify-content-between">
                  <div>Sugestão {{ index + 1 }}</div>
                  <button
                    v-if="index > 1"
                    class="btn btn-link text-danger btn-sm p-0"
                    title="Remover sugestão"
                    @click="removeOption(index)"
                  >
                    <i class="ph ph-x-circle"></i>
                  </button>
                </div>
              </legend>
              <div class="mb-3">
                <label class="form-label">Data:</label>
                <input
                  type="date"
                  class="form-control"
                  :min="tomorrow()"
                  v-model="calendar.date"
                />
              </div>
              <div class="mb-2">
                <label class="form-label">Hora:</label>
                <select class="form-select" v-model="calendar.time">
                  <option value="06:00">06:00</option>
                  <option value="06:30">06:30</option>
                  <option value="07:00">07:00</option>
                  <option value="07:30">07:30</option>
                  <option value="08:00">08:00</option>
                  <option value="08:30">08:30</option>
                  <option value="09:00">09:00</option>
                  <option value="09:30">09:30</option>
                  <option value="10:00">10:00</option>
                  <option value="10:30">10:30</option>
                  <option value="11:00">11:00</option>
                  <option value="11:30">11:30</option>
                  <option value="12:00">12:00</option>
                  <option value="12:30">12:30</option>
                  <option value="13:00">13:00</option>
                  <option value="13:30">13:30</option>
                  <option value="14:00">14:00</option>
                  <option value="14:30">14:30</option>
                  <option value="15:00">15:00</option>
                  <option value="15:30">15:30</option>
                  <option value="16:00">16:00</option>
                  <option value="16:30">16:30</option>
                  <option value="17:00">17:00</option>
                  <option value="17:30">17:30</option>
                  <option value="18:00">18:00</option>
                  <option value="18:30">18:30</option>
                  <option value="19:00">19:00</option>
                </select>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-end">
      <router-link
        to="/managers/home"
        class="btn btn-link text-secondary"
        title="Cancelar Cadastro do Agendamento"
      >
        Cancelar
      </router-link>
      <button
        type="button"
        class="btn btn-flat-secondary ms-2"
        title="Voltar para Etapa Anterior"
        @click="$emit('backStep')"
      >
        Voltar Etapa
      </button>
      <button
        type="button"
        class="btn btn-secondary ms-2"
        title="Continuar"
        :disabled="disabledButton"
        @click="nextStep()"
      >
        Continuar
      </button>
    </div>
  </div>
</template>

<script>
import { tomorrow } from '@/utils/date'

export default {
  name: 'SuggestSchedule',

  data() {
    return {
      disabledButton: true,
      calendars: [
        {
          date: null,
          time: null,
        },
        {
          date: null,
          time: null,
        },
        {
          date: null,
          time: null,
        },
      ],
      tomorrow,
    }
  },

  watch: {
    calendars: {
      handler(newVal, oldVal) {
        this.validationData()
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    addOption() {
      this.calendars.push({
        date: null,
        time: null,
      })
    },

    removeOption(index) {
      this.calendars.splice(index, 1)
    },

    validationData() {
      let hasEmptyValue = false

      this.calendars.forEach((item) => {
        if (item.date == null || item.time == null) {
          hasEmptyValue = true
        }
      })

      this.disabledButton = !!hasEmptyValue
    },

    nextStep() {
      this.$emit('setDateTimeSugest', this.calendars)
    },
  },
}
</script>
