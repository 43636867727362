<template>
  <div class="card">
    <div class="card-header">
      <h4 class="text-secondary mb-0">Contato</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h6>Dados do Funcionário:</h6>
        </div>
        <div class="col-12">
          <p><strong>Nome:</strong> {{ scheduling.collaborator.NOME }}</p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>Unidade:</strong> {{ scheduling.collaborator.NOMEUNIDADE }}
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p><strong>Setor:</strong> {{ scheduling.collaborator.NOMESETOR }}</p>
        </div>
        <div class="col-12 col-md-6">
          <p><strong>Cargo:</strong> {{ scheduling.collaborator.NOMECARGO }}</p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>Matrícula:</strong>
            {{ scheduling.collaborator.MATRICULAFUNCIONARIO }}
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>CPF:</strong> {{ scheduling.collaborator.CPFFUNCIONARIO }}
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>Data de Nascimento:</strong>
            {{ scheduling.collaborator.DATA_NASCIMENTO }}
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>Data de Admissão:</strong>
            {{ scheduling.collaborator.DATA_ADMISSAO }}
          </p>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <h6>Forma de Contato:</h6>
        </div>
        <div class="col-4 text-center">
          <label class="cursor-pointer" for="useWhatsApp" title="WhatsApp">
            <i class="ph-2x ph-whatsapp-logo"></i>
            <p class="mb-1">WhatsApp</p>
          </label>
          <div
            class="form-check form-switch d-flex justify-content-center"
            title="WhatsApp"
          >
            <input
              type="checkbox"
              class="form-check-input form-check-input-secondary cursor-pointer"
              id="useWhatsApp"
              v-model="useWhatsApp"
            />
          </div>
        </div>
        <div class="col-4 text-center">
          <label class="cursor-pointer" for="useSms" title="SMS">
            <i class="ph-2x ph-chat-centered-text"></i>
            <p class="mb-1">SMS</p>
          </label>
          <div
            class="form-check form-switch d-flex justify-content-center"
            title="SMS"
          >
            <input
              type="checkbox"
              class="form-check-input form-check-input-secondary cursor-pointer"
              id="useSms"
              v-model="useSms"
            />
          </div>
        </div>
        <div class="col-4 text-center">
          <label class="cursor-pointer" for="useEmail" title="E-mail">
            <i class="ph-2x ph-envelope-simple"></i>
            <p class="mb-1">E-mail</p>
          </label>
          <div
            class="form-check form-switch d-flex justify-content-center"
            title="E-mail"
          >
            <input
              type="checkbox"
              class="form-check-input form-check-input-secondary cursor-pointer"
              id="useEmail"
              v-model="useEmail"
            />
          </div>
        </div>
      </div>
      <hr />
      <div class="row g-3">
        <div class="col-12">
          <h6 class="mb-0">Dados de Contato:</h6>
        </div>
        <div class="col-12 col-md-6">
          <div>
            <label class="form-label fw-semibold text-primary">Celular:</label>
            <input
              type="text"
              class="form-control"
              :class="{
                'is-invalid': phoneInvalid,
              }"
              placeholder="(00) 98888-8888"
              v-model="contact.phone"
              v-maska="['(##) ####-####', '(##) #####-####']"
            />
            <span v-if="phoneInvalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div>
            <label class="form-label fw-semibold text-primary">E-mail:</label>
            <input
              type="text"
              class="form-control"
              :class="{
                'is-invalid': emailInvalid,
              }"
              placeholder="usuario@email.com.br"
              v-model="contact.email"
            />
            <span v-if="emailInvalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-end">
      <router-link
        to="/managers/home"
        class="btn btn-link text-secondary"
        title="Cancelar Cadastro do Agendamento"
      >
        Cancelar
      </router-link>
      <button
        type="button"
        class="btn btn-secondary ms-2"
        title="Continuar"
        :disabled="disabledButton"
        @click="nextStep()"
      >
        Continuar
      </button>
    </div>
  </div>
</template>

<script>
import { maska } from 'maska'
import { mapGetters } from 'vuex'
import contactTypeEnum from '@/enum/contactTypeEnum'

export default {
  name: 'Contact',

  directives: { maska },

  data() {
    return {
      disabledButton: true,
      useWhatsApp: true,
      useSms: false,
      useEmail: false,
      contact: {
        phone: '',
        email: '',
      },
      phoneInvalid: false,
      emailInvalid: false,
    }
  },

  computed: {
    ...mapGetters(['scheduling']),
  },

  watch: {
    'useWhatsApp': {
      handler(newVal, oldVal) {
        this.canContinue()
      },
      immediate: true,
    },
    'useSms': {
      handler(newVal, oldVal) {
        this.canContinue()
      },
      immediate: true,
    },
    'useEmail': {
      handler(newVal, oldVal) {
        this.canContinue()
      },
      immediate: true,
    },
    'contact.phone': {
      handler(newVal, oldVal) {
        this.canContinue()
      },
      immediate: true,
    },
    'contact.email': {
      handler(newVal, oldVal) {
        this.canContinue()
      },
      immediate: true,
    },
  },

  methods: {
    canContinue() {
      if (this.useSms || this.useWhatsApp) {
        this.contact.phone = this.contact.phone ?? ''
        if (this.contact.phone.length < 14) {
          this.disabledButton = true
          this.phoneInvalid = true
          return
        }
        this.phoneInvalid = false
      } else {
        this.phoneInvalid = false
      }

      if (this.useEmail) {
        const validRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

        this.contact.email = this.contact.email ?? ''

        const isValidEmail = this.contact.email.match(validRegex)

        if (!isValidEmail) {
          this.emailInvalid = true
          this.disabledButton = true
          return
        }
        this.emailInvalid = false
      } else {
        this.emailInvalid = false
      }

      if (!this.useEmail && !this.useSms && !this.useWhatsApp) {
        this.disabledButton = true
        return
      }
      this.disabledButton = false
    },

    nextStep() {
      const contactMethod = []

      if (this.useWhatsApp) {
        contactMethod.push({
          contactType: contactTypeEnum.WHATSAPP,
          value: this.contact.phone,
        })
      }
      if (this.useSms) {
        contactMethod.push({
          contactType: contactTypeEnum.SMS,
          value: this.contact.phone,
        })
      }
      if (this.useEmail) {
        contactMethod.push({
          contactType: contactTypeEnum.EMAIL,
          value: this.contact.email,
        })
      }

      this.$emit('setContact', contactMethod)
    },
  },

  mounted() {
    this.contact = {
      phone: this.scheduling.collaborator.TELEFONECELULAR,
      email: this.scheduling.collaborator.EMAILPESSOAL,
    }
  },
}
</script>
